import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, Observable } from 'rxjs'
import { apis } from 'src/environments/environment'

@Injectable()
export class DataService {
    private baseUrl = `${apis.baseUrl}/admin/mentors`
    private data = new BehaviorSubject<Array<any>>([{ fetching: true }])
    data$ = this.data.asObservable()

    constructor(public http: HttpClient) { }

    getList(params: any): Observable<any> {
        const url = `${this.baseUrl}`

        return this.http.get<any>(url, { params })
    }

    activate(params: any): Observable<any> {
        const url = `${this.baseUrl}/activate`
        return this.http.post<any>(url, params)
    }

    deactivate(params: any): Observable<any> {
        const url = `${this.baseUrl}/deactivate`
        return this.http.post<any>(url, params)
    }

    delete(params: any): Observable<any> {
        const url = `${this.baseUrl}/delete`
        return this.http.post<any>(url, params)
    }

    getTransactions(params: any): Observable<any> {
        const url = `${this.baseUrl}/transactions`
        return this.http.get<any>(url, { params })
    }

    addTransaction(params: any): Observable<any> {
        const url = `${this.baseUrl}/add-transaction`
        return this.http.post<any>(url, params)
    }

    getParallelList(): Observable<any> {
        const url = `${apis.baseUrl}/admin/dropdown/mentors`
        return this.http.get<any>(url, {})
    }
}
