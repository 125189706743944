import { IAlertService } from 'src/app/libs/ialert/ialerts.service'
import { Component, OnInit } from '@angular/core'
import { DataService } from './data.service'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { UIHelpers } from 'src/app/helpers/ui-helpers'
import { ApiService } from 'src/app/services/api.service'
import { Router } from '@angular/router'
import moment from 'moment'
import { ConstantsService } from 'src/app/services/constants.service'
import { Pagination } from 'src/app/interfaces/response'


@Component({
  selector: 'app-mentor-payments',
  templateUrl: './mentor-payments.component.html',
  styleUrls: ['./mentor-payments.component.css']
})
export class MentorPaymentsComponent implements OnInit {
  dataStatus = 'fetching'
  dataForm: FormGroup
  searchMentorList: Array<any> = []
  selectedIndex: number
  paymentMethodList: Array<any>
  mentorBalance = 0
  displayTransactionDetails: any
  mentorId: any
  pagination: Pagination<any>
  moment = moment
  payLoading = false

  mentorList: Array<any> = []
  filters: any = {
    page: 1,
    per_page: 10,
    startDate: moment().subtract(3, 'months').toDate(),
    endDate: new Date(),
    userId: ''
  }
  breadCrum = [
    {
      link: '/admin/mentor-payments',
      value: 'Mentor Payments'
    }
  ]
  waitingt = {
    save: false,
    details: false
  }

  waiting = {
    save: false,
    details: false
  }
  loaderOptions = {
    rows: 5,
    cols: 5,
    colSpans: {
      0: 1
    }
  }

  constructor(
    public ds: DataService,
    public fb: FormBuilder,
    public ui: UIHelpers,
    private alert: IAlertService,
    public api: ApiService,
    public router: Router,
    public cs: ConstantsService

  ) {
    this.dataForm = this.fb.group({
      id: new FormControl(null, [Validators.required]),
      amount: new FormControl(null, [Validators.required]),
      description: new FormControl('', [Validators.required]),
      //paymentMethod: new FormControl('', [Validators.required]),
    })

    this.paymentMethodList = this.cs.PAYMENT_METHOD

  }
  getParallels() {
    this.ds.getParallelList().subscribe((resp: any) => {
      this.searchMentorList = resp.data || []
    })
  }
  get g() {
    return this.dataForm.controls
  }

  save(f: any) {
    this.payLoading = true
    if (this.dataForm.value.amount <= 0) {
      this.payLoading = false
      this.alert.error('amount should be greater than 0')
      this.dataForm.controls.amount.setValue(null)
      return
    }
    if (this.dataForm.invalid) {
      this.payLoading = false
      this.alert.error('Please fill-in valid data in all fields & try again.')
      return
    }
    const params = {
      userId: this.dataForm.value.id,
      amount: this.dataForm.value.amount,
      paymentMethod: this.dataForm.value.paymentMethod,
      description: this.dataForm.value.description
    }
    this.ds.addTransaction(params).subscribe((resp: any) => {
      this.payLoading = false
      if (resp.success === true) {
        this.alert.success('Transaction Added Successfully')
        this.searcha()
      } else {
        this.alert.error(resp?.errors.general || '')
      }
      f.resetForm()
    })

  }

  showBalance(mentorId: number) {
    const index = this.searchMentorList.findIndex((e) => {
      return e.id === mentorId
    })
    this.mentorBalance = this.searchMentorList[index]?.balance
  }

  showTransactionsDetails() {
    if (this.mentorId === 0 || this.mentorId === null || this.mentorId === undefined) {
      this.displayTransactionDetails = false
      this.alert.error('Please Select Mentor')
    } else {
      this.displayTransactionDetails = true
      this.filters.userId = this.mentorId
      this.search()

    }
  }

  ngOnInit() {
    this.getParallels()
  }

  search(): void {
    this.waiting.save = true
    const paramsToSend: any = { ...this.filters }
    paramsToSend.startDate = moment(paramsToSend.startDate).format('YYYY-MM-DD')
    paramsToSend.endDate = moment(paramsToSend.endDate).format('YYYY-MM-DD')

    this.ds.getTransactions(paramsToSend).subscribe((resp: any) => {
      this.waiting.save = false
      if (resp.success === true) {
        this.mentorList = resp.data?.data?.data
        this.pagination = resp.data?.data as Pagination
        this.dataStatus = 'done'
        this.filters.startDate = new Date(this.filters.startDate)
        this.filters.endDate = new Date(this.filters.endDate)
      }
    })
  }

  searcha() {
    this.filters.userId = this.mentorId
    const paramsToSend: any = { ...this.filters }
    paramsToSend.startDate = moment(paramsToSend.startDate).format('YYYY-MM-DD HH:mm:ss')
    paramsToSend.endDate = moment(paramsToSend.endDate).format('YYYY-MM-DD HH:mm:ss')
    this.showTransactionsDetails()

  }

  setPagination(page: number) {
    this.filters.page = page
    const paramsToSend: any = { ...this.filters }
    paramsToSend.startDate = moment(paramsToSend.startDate).format('YYYY-MM-DD HH:mm:ss')
    paramsToSend.endDate = moment(paramsToSend.endDate).format('YYYY-MM-DD HH:mm:ss')
    this.showTransactionsDetails()
  }

}
