<h1 class="title">Mentor Payments</h1>
<app-breadcrums [data]="breadCrum"></app-breadcrums>
<form [formGroup]="dataForm" (ngSubmit)="save(f)" #f="ngForm">
  <div class="row">
    <div class="col-md-6">
      <label>Mentor <span class="mendatory">*</span></label>
      <ng-select class="autocomplete cg" (change)="showBalance($event)" formControlName="id" [(ngModel)]="mentorId"
        placeholder="Select Mentor">
        <ng-option *ngFor="let mentor of searchMentorList" [value]="mentor.id">{{ mentor.name }}</ng-option>
      </ng-select>
      <div *ngIf="ui.checkErrors(g.id, f)" class="errors">
        <p *ngIf="g.id.errors?.required">Mentor is required</p>
      </div>
    </div>
    <div class="col-md-6">
      <div class="cg-input-group mt-1">
        <label>Amount <span class="mendatory">*</span></label>
        <input type="number" formControlName="amount" class="cg mt-0" placeholder="Enter Amount"
          [ngClass]="{'form-submitted': f.submitted}">
      </div>
      <div *ngIf="ui.checkErrors(g.amount, f)" class="errors">
        <p *ngIf="g.amount.errors?.required">Amount is required</p>
      </div>
    </div>

    <div class="col-md-6">
      <div class="cg-input-group">
        <label class="mb-0">Description </label>
        <textarea formControlName="description" cols="20" rows="3" class="cg mt-0" placeholder="Enter Description"
          [ngClass]="{'form-submitted': f.submitted}"></textarea>
        <div *ngIf="ui.checkErrors(g.description, f)" class="errors">
          <p *ngIf="g.description.errors?.required">Description is required</p>
        </div>
      </div>

    </div>

    <!-- <div class="col-md-3">
      <label>Payment Method <span class="mendatory">*</span></label>
      <select class="cg" formControlName="paymentMethod">
        <option value="">Select Any</option>
        <ng-container *ngFor="let d of paymentMethodList">
          <option [value]="d">{{ d }}</option>
        </ng-container>
      </select>
      <div *ngIf="ui.checkErrors(g.paymentMethod, f)" class="errors">
        <p *ngIf="g.paymentMethod.errors?.required">Payment Method is required</p>
      </div>
    </div> -->
    <div class="col-md-12">
      <div class="mt-4 d-flex justify-content-end">
        <button type="submit" class="cg primary btn-mr" [disabled]="payLoading"
          [ngClass]="payLoading?'in-progress':''">Pay Now
          <span></span><span></span><span></span>
        </button>
        <button type="button" class="cg secondary ms-2" (click)="showTransactionsDetails()">Transaction Details
        </button>
      </div>
    </div>

   

    <div class="col-md-6"></div>

    <div class="col-md-3 text-end">
      <label class="primary">Current Balance</label>
      <div>
        <span *ngIf="mentorBalance >= 0" class="secondary">${{ mentorBalance | number: '1.2-2' }}</span>
        <span *ngIf="mentorBalance < 0" class="secondary">-${{ mentorBalance * -1 | number: '1.2-2' }}</span>

      </div>
    </div>
  </div>

</form>


<div *ngIf="displayTransactionDetails">
  <div class="row">
    <div class="d-flex justify-content-between">
      <div class="col-md-3 cg-input-group ms-2">
        <label>Start Date</label>
        <input type="text" class="cg" [(ngModel)]="filters.startDate" bsDatepicker
          [bsConfig]="{ containerClass: 'theme-orange', dateInputFormat: this.cs.DATE_TIME_FORMAT.SYN_DATE, adaptivePosition: true, showWeekNumbers: false }"
          placeholder="Start Date" />
      </div>
      <div class="col-md-3 cg-input-group ms-2">
        <label>End Date</label>
        <input type="text" class="cg" [(ngModel)]="filters.endDate" bsDatepicker
          [bsConfig]="{ containerClass: 'theme-orange', dateInputFormat: this.cs.DATE_TIME_FORMAT.SYN_DATE, adaptivePosition: true, showWeekNumbers: false }"
          placeholder="End Date" />
      </div>
      <div class="col cg-input-group d-flex justify-content-between align-items-end ms-2">
        <div class="btn-group" dropdown>
          <button [disabled]="waiting.save" [ngClass]="waiting.save ? 'in-progress' : ''" type="button"
            class="cg secondary" (click)="filters.page = 1; searcha()">
            Search
            <span></span>
            <span></span>
            <span></span>
          </button>
        </div>
      </div>
    </div>

  </div>
  <div class="box">
    <div class="table-responsive">
      <table class="cg primary">
        <thead class="sticky">
          <tr>
            <th>Date</th>
            <th style="width: 40%;">Description</th>
            <th class="text-center">Debit</th>
            <th class="text-center">Credit</th>
            <th class="text-center">Balance</th>
          </tr>
        </thead>

        <tbody *ngIf="dataStatus === 'fetching' && this.mentorId > 1" [appSkeletonTabelLoader]="loaderOptions">
        </tbody>

        <tbody *ngIf="mentorList.length > 0">
          <tr *ngFor="let d of mentorList; let i = index">
            <td>{{moment.utc(d.created_at).local().format('MM/DD/YYYY')}}</td>

            <td>{{ d.description }}</td>

            <td class="text-end">
              <ng-container *ngIf="d.amount<0"> -${{ d.amount*-1 | number: '1.2-2'}} </ng-container>
            </td>

            <td class="text-end">
              <ng-container *ngIf="d.amount>0"> ${{ d.amount | number: '1.2-2'}} </ng-container>
            </td>


            <td class="text-end">
              <ng-container *ngIf="d.mentor.balance>0"> ${{d.mentor.balance | number: '1.2-2'}}</ng-container>
              <ng-container *ngIf="d.mentor.balance<0"> -${{d.mentor.balance*-1 | number: '1.2-2'}}</ng-container>
            </td>
          </tr>
        </tbody>

        <tbody *ngIf="dataStatus === 'done' && mentorList.length === 0">
          <tr>
            <td colspan="5">
              <app-no-data-found></app-no-data-found>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <app-ipagination [pagination]="pagination" [activePage]="filters.page" (pageChangeEvent)="setPagination($event)"
    *ngIf="dataStatus === 'done'"></app-ipagination>
</div>